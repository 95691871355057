<template>
  <!-- 系统设置 - 资讯公告-详情 -->
  <div class="pageContol aeAdvisoryNotice">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i class="el-icon-arrow-right"></i>
          <a href="javascript:;" class="cur-a">资讯公告-详情</a>
        </span>
      </div>
      <!-- 编辑内容 -->
      <div class="framePage-body">
        <el-form ref="form" :rules="rules" :model="retrievalData" label-width="120px">
          <el-form-item label="资讯标题" prop="name">
            <el-input
              style="width:284px"
              size="small"
              placeholder="请输入资讯标题"
              v-model="retrievalData.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="发布系统" prop="type">
            <el-checkbox-group size="small" v-model="retrievalData.type">
              <el-checkbox label="2" >小程序</el-checkbox>
              <el-checkbox label="1">App</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="行政区划">
            <el-cascader
              v-model="retrievalData.areaId"
              style="width:284px"
              :options="areatreeList"
              :props="propsarea"
              clearable
              filterable
              size="small"
              placeholder="请选择行政区划"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="上传封面"
            prop="thumbnail"
            :class="[rotationId ? 'item-disabled' : '']"
            required
          >
            <el-upload
              :on-change="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="retrievalData.thumbnail || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
            <p
              style="color:#f46173;line-height:25px;"
            >请上传 640*357 （长 X 宽）像素的PNG,JPG格式的图片，且文件大小不能超过1MB！</p>
          </el-form-item>
          <el-form-item label="内容链接">
            <el-input
              style="width:284px"
              size="small"
              placeholder="请输入内容链接"
              v-model="retrievalData.advisoryPath"
            ></el-input>
          </el-form-item>
          <el-form-item label="人气">
            <el-input-number size="small" v-model="retrievalData.popularity" :min="0" :controls="false"></el-input-number>
          </el-form-item>
          <el-form-item label="资讯内容：" class="form-item" style="overflow: hidden;">
            <div ref="editor" class="editor" style="width:100%;max-width:1500px" />
          </el-form-item>
          <el-form-item class="czzjf">
            <el-button class="bgc-bv" @click="cancel">取消</el-button>
            <el-button type="primary" class="bgc-bv" @click="addEdit('form')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
import UploadPic from "@/mixins/UploadPic";

import { validateURL } from "@/utils/common"; // 校验url
let editor = {};
export default {
  name: "operate/aeAdvisoryNotice",
  mixins: [UploadPic],
  data() {
    return {
      // 资讯列表传过来的id
      id: "",
      // 行政区划-下拉数据
      areatreeList: [],
      // 行政区划-默认数据
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      // 消息详情数据
      retrievalData: {
        name: "", // 资讯名称s
        type: [], // 系统类型
        areaId: "", // 行政区划
        thumbnail: "", //图片地址
        fileKey: "", //上传的图片
        advisoryPath: "", // 内容链接
        context: "", // 符文本内容
        popularity: 0 // 人气
      },
      // 校验输入
      rules: {
        name: [{ required: true, message: "请输入资讯标题", trigger: "blur" }],
        type: [
          { required: true, message: "请选择发布系统", trigger: "change" }
        ],
        thumbnail: [
          { required: true, message: "请上传封面", trigger: "change" }
        ]
      }
    };
  },
  mounted() {
    this.editorInit();
  },
  computed: {},
  created() {
    // 初始化 - 资讯列表传过来的id
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.$post("/biz/advisory/getInfo", {
        advisoryId: this.id
      }).then(res => {
        this.retrievalData.name = res.data.advisoryName
        this.retrievalData.type = res.data.terminal.split(",")
        this.retrievalData.areaId = res.data.areaId
        this.retrievalData.fileKey = res.data.picPath
        this.retrievalData.thumbnail = res.data.picUrl
        this.retrievalData.advisoryPath = res.data.advisoryPath
        this.retrievalData.context = res.data.advisoryContent
        this.retrievalData.popularity = res.data.popularity || 0
        editor.txt.html(res.data.advisoryContent);
      });
    }
    // 初始化 - 调取行政区划
    this.$post("/sys/area/tree").then(ret => {
      this.areatreeList = ret.data;
    });
  },
  methods: {
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.retrievalData.thumbnail = result.data.fileURL || "";
            this.retrievalData.fileKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
      }
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false; //富文本编辑器中点击图片上传选择图片后激发的事件：
      let _this = this;
      editor.config.customUploadImg = function(files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "COURSE_WARE");
          formData.append(
            "fileType ",
            files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then(result => {
            insert(result.data.fileURL);
          });
        }
      };
      editor.config.onchange = html => {
        this.retrievalData.context = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    // 取消
    cancel() {
      this.$router.push({
        path: "/web/operate/advisoryNotice",
        query: {
          liuliuliu: true
        }
      });
    },
    // 添加/编辑
    addEdit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (
            this.retrievalData.advisoryPath &&
            !validateURL(this.retrievalData.advisoryPath)
          ) {
            return false;
          } 
          let str = this.retrievalData.context.replace(/<[^>]+>/g, "");
          str.replace(/\s*/g, "");
          console.log(str);
          if (!str&&!this.retrievalData.advisoryPath) {
             this.$message({
                type: "warning",
                message: "内容链接与资讯内容必须填写一项"
              });
            return false;
          }
          let data = {
            advisoryName: this.retrievalData.name,
            terminal: this.retrievalData.type.join(","),
            areaId: this.retrievalData.areaId,
            picPath: this.retrievalData.fileKey,
            advisoryPath: this.retrievalData.advisoryPath,
            popularity: this.retrievalData.popularity,
            advisoryContent: this.retrievalData.context
          };
          if (this.id) {
            data.advisoryId = this.id;
            this.$post("/biz/advisory/modify", data).then(res => {
              this.$message.success(res.message);
              this.$router.push({
                path: "/web/operate/advisoryNotice",
                query: {
                  liuliuliu: true
                }
              });
            });
          } else {
            this.$post("/biz/advisory/insert", data).then(res => {
              this.$message.success(res.message);
              this.$router.push({
                path: "/web/operate/advisoryNotice",
                query: {
                  liuliuliu: true
                }
              });
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 18px;
}
.czzjf {
  width: 150px;
  margin: auto;
}
.czzjf /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
</style>
